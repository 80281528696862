<template>
  <div class="leave_info">
    <el-card>
      <div class="commication_id">
        <div class="commication_list">
          <div v-for="(item, index) in communication" :key="index">
            <div class="data_time">
              <p v-if="item.onCreateTime">{{ item.onCreateTime }}</p>
              <p v-else-if="item.dayTime">{{ item.dayTime }}</p>
              <p v-else-if="item.nowCreateTime">{{ item.nowCreateTime }}</p>
              <p></p>
              <p></p>
            </div>
            <div class="commication_list_left" v-if="item.adminId === 0">
              <div class="commication_list_left_image">
                <img :src="item.userImage" />
              </div>
              <div class="commication_list_left_flex">
                <div class="commication_list_left_text">
                  <div class="demo"></div>
                  {{ item.content }}
                </div>
              </div>
            </div>
            <div class="commication_list_right" v-else>
              <div class="commication_list_right_flex">
                <div class="commication_list_right_text">
                  <div class="demo"></div>
                  {{ item.content }}
                </div>
              </div>
              <div class="commication_list_right_image">
                <img :src="item.adminImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="input">
        <el-input
          type="textarea"
          :rows="8"
          placeholder="请输入"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div class="bottom">
        <el-button type="primary" @click="submit">回复</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
const defaultQueryInfo = {
  pageNumber: 0,
  pageSize: 1000,
};
import { listDetails, communicationsSave } from "@/api/message.js";
export default {
  name: "leaveInfo",
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      communication: [],
      textarea: "",
      parentId: "",
    };
  },
  computed: {
    userId() {
      return this.$route.query.userId;
    },
  },
  created() {
    this.search();
  },
  methods: {
    //回复新增
    async submit() {
      const query = {
        parentId: this.parentId * 1,
        content: this.textarea,
      };
      const { data: res } = await communicationsSave(query);
      console.log(res);
      if (res.code === 10200) {
        this.$message.success("发送成功");
        this.textarea = "";
        this.search();
      } else {
        this.$message.error("发送失败，请稍候重试。");
      }
    },
    //获取单个数据
    async search() {
      this.queryInfo.userId = this.userId * 1;
      const { data: res } = await listDetails(this.queryInfo);
      if (res.code === 10200) {
        this.communication = res.data.data;
        this.parentId = res.data.data[0].id;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.leave_info {
  .data_time {
    width: 100%;
    overflow: hidden;
    text-align: center;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #999;
      margin-bottom: 4px;
    }
  }
  width: 100%;
  overflow: auto;
  .commication_id {
    height: 400px;
    overflow: auto;
    .commication_list {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      padding: 30px 0;

      .commication_list_left {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding-right: 44px;
        display: flex;
        margin-bottom: 20px;
        flex-direction: row;

        .commication_list_left_image {
          width: 44px;
          height: 44px;
          overflow: hidden;
          margin-right: 15px;
          border-radius: 50%;

          img {
            width: 44px;
            height: 44px;
            overflow: hidden;
            border-radius: 50%;
          }
        }

        .commication_list_left_flex {
          flex: 1;
        }

        .commication_list_left_text {
          position: relative;
          display: inline-block;
          float: left;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 12px;
          background-color: #f6f6f6;
          color: #151515;
        }

        .demo {
          width: 0;
          position: absolute;
          height: 0;
          top: 12px;
          left: -15px;
          border-width: 8px;
          border-style: solid;
          border-color: transparent #f6f6f6 transparent transparent;
        }
      }

      .commication_list_right {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding-left: 44px;
        display: flex;
        margin-bottom: 20px;
        flex-direction: row;

        .commication_list_right_image {
          width: 44px;
          height: 44px;
          overflow: hidden;
          margin-left: 15px;
          border-radius: 50%;

          img {
            width: 44px;
            height: 44px;
            overflow: hidden;
            border-radius: 50%;
          }
        }

        .commication_list_right_flex {
          flex: 1;
        }

        .commication_list_right_text {
          position: relative;
          display: inline-block;
          float: right;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 12px;
          background-color: #cbc4f9;
          color: #ffffff;
        }

        .demo {
          width: 0;
          position: absolute;
          height: 0;
          top: 12px;
          right: -12px;
          border-width: 6px;
          border-style: solid;
          border-color: transparent transparent transparent #cbc4f9;
        }
      }
    }
  }

  .input {
    width: 100%;
    overflow: hidden;
  }

  .bottom {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    /deep/ .el-button--primary {
      background-color: #8483d0;
      border-color: #8483d0;
    }
  }
}
</style>